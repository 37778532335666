export default function Header() {
    return (
        <header className="teal-bg container-fluid white">
            <nav className="navbar navbar-expand-lg">
                <div className="container-fluid justify-content-center">
                    <a href="/" className="navbar-brand">
                        <h1 className="h1 white">Braiders Near Me</h1>
                    </a>
                </div>
            </nav>
        </header>
    )
}