export function CallToActionButton({ text = "Sign Up Now" }) {
    return (
        <a className="btn btn-lg btn-teal" href="https://braidersnearme.ca/accounts/signup/">
            {text}
        </a>
    )
}

export function Testimonial({ body, author }) {
    return (
        <div className="card my-4">
            <div className="card-body">
                <blockquote className="blockquote mb-0">
                    <p>
                        {body}
                    </p>
                    <footer className="blockquote-footer">
                        <cite title="Touched by Angel">{author}</cite>
                    </footer>
                </blockquote>
            </div>
        </div>
    )
}

export function NewsQuote({ align = undefined, children, source = undefined, sourceurl = undefined }) {
    return (
        <p>
            <figure class={align}>
                <blockquote class="blockquote">
                    {children}
                </blockquote>
                <figcaption class="blockquote-footer">
                    <cite title="Source Title"><a href={sourceurl} target="blank">{source}</a></cite>
                </figcaption>
            </figure>
        </p>
    )
}