import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import './App.css';
import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';
import Section from './components/Section/Section';
import { CallToActionButton, Testimonial, NewsQuote } from './components/Components';
import { TESTIMONIALS } from './data/testimonials';
import { HEADLINES } from "./data/new";

function App() {
  return (
    <div className="App">
      <Header />
      <main className='container'>
        <Section>
          <h2 className="h2 text-center">What's this about?</h2>
          <p>
            At the start of 2023, while running a hair extensions and beauty supply business, we received a lot of requests from customers in our city, Halifax, NS, asking for help to find a stylist.
          </p>
          <p>After a quick survey and some research, we soon realised an issue across Canada - <i>there's no reliable means of finding hair stylists in any city.</i></p>
        </Section>
        <Section>
          <h3 className="h3 text-center mb-3">Some sources so you know this isn't made up</h3>
          <div className="news-quotes">
            <div id="news-text">
              {
                HEADLINES.map((headline, index) => {
                  return <NewsQuote key={index} align={index % 2 === 0 ? "text-end" : ""} source={headline.source} sourceurl={headline.url}>{headline.title}</NewsQuote>
                })
              }
            </div>
            {/* <div id="news-mobile-img">
              <img src={HEADLINES[0].image} />
            </div>
            <div id="news-images">

            </div> */}
          </div>
          <h3 className="h3 text-center">Enter, The Directory For Braiders</h3>
          <p>
            With most stylists opting to use instagram for promotion and communication, newcomers and other potential clients weren't able to find services easily. (Instagram isn't great at helping you find things that aren't ads)
          </p>
          <p>
            So we saw both an opportunity and a need for Canadians, especially those of Afro and/or Afro-Caribbean decent, whose haircare tends to be neglected, to create Braiders Near Me, a platform for hair stylists across Canada.
          </p>
        </Section>
        <Section>
          <h2 className="h2 text-center">So, Why list your hair styling business?</h2>
          <ul id="feature_list">
            <li>Make it easier for newcomers and potential clients to find you</li>
            <li>Get a free, dedicated page for your business</li>
            <li>Free promotion of your services across social media</li>
            <li>Access digital marketing tools and resources for your brand</li>
            <li>Enjoy new and upcoming features</li>
          </ul>
          <div className="row justify-content-center my-2">
            <div className="col-auto">
              <CallToActionButton text='List Your Business Here' />
            </div>
          </div>
        </Section>
        <Section>
          <h2 className="h2 text-center">Hear from some stylists...</h2>
          {TESTIMONIALS.map((item) => {
            return <Testimonial body={item.body} author={item.author} />
          })}
          <div className="row justify-content-center my-2">
            <div className="col-auto">
              <CallToActionButton text='Join Your Peers' />
            </div>
          </div>
        </Section>
        <Section>
          <h2 class="h2 text-center mb-4">"I love this! But..." (FAQ)</h2>
          <ul class="faq_list">
            <li>
              <p class="question">I already see my business listed</p>
              <p>We've listed as many stylists as possible from across the web so users would be able to find services. But these are temporary and also don't include key features like dedicated pages, services, business profiles etc. To use those, you need to <a href='https://braidersnearme.ca/accounts/signup/' target="blank">create an account</a>.</p>
            </li>
            <li>
              <p class="question">I'm fully booked all-year round</p>
              <p>Many businesses would love to have this problem so a sincere congrats on that. However, clients move, you might move etc. And maybe, just maybe, it's time to expand your business?</p>
            </li>
            <li>
              <p class="question">How much does it cost?</p>
              <p>
                <b><u>$0.00</u></b>. Listing and promoting your business, along with dedicated
                pages will always be 100% free at <b>Braiders Near Me</b>.
              </p>
            </li>
            <li>
              <p class="question">I don't own a salon...</p>
              <p>
                That's fine. Do you work with hair? We welcome stylists in all their forms including home-based, salon owners and mobile/travel.
              </p>
            </li>
            <li>
              <p class="question">
                I'm not a braider, just a/an [insert other hair-styling professional
                here]
              </p>
              <p>
                All hair-related beauty professionals from wig fitters,
                natural hair coaches etc. are welcome. As long as you offer this as a service, you're welcome to join.
              </p>
            </li>
            <li>
              <p class="question">
                I'm not a braider or a hair stylist. Just a/an [insert other beauty, cosmetic and personal care professional]
              </p>
              <p>
                Barber? Lash/Nail Tech? Make-up artist? We're working on that too. <a href="https://forms.gle/JPzMxG7MRLEQKq8B6" target="blank">Click here to join the waitlist</a>. We'll let you know once it's ready to launch.
              </p>
            </li>
            <li>
              <p class="question">
                I'm not a [insert "visible minority" group term here]
              </p>
              <p>
                Are you visible though? You got a phone and internet? You got hair and/or work with hair? Then you're good.
              </p>
              <p className="small">Side note: invisible people are welcome too. Just know very few people can work on your hair.</p>
            </li>
            {/* <li>
              <p class="question">Is this available for stylists based in the US?</p>
              <p>Unfortunately now BUT our friend <a href="https://www.instagram.com/blackbeautynearyou/" target="blank" rel="noreferrer">blackbeautynearyou</a> is doing something simila</p>
            </li> */}
            <li>
              <p class="question">Great! Where do I sign up?</p>
            </li>
          </ul>
          <div class="text-center my-4">
            <CallToActionButton text='Easy, Start Here!' />
          </div>
        </Section>
      </main>
      <Footer />
    </div >
  );
}

export default App;
