export const HEADLINES = [
    {
        'title': "Push for N.B. stylists to be trained to work with Black and textured hair",
        'source': "CBC News",
        'url': "https://www.cbc.ca/news/canada/new-brunswick/black-hair-salons-services-petition-1.5667387",
        'image': '/public/news_clips/cbc2.png',
        'image-mobile': '/public/news_clips/cbc2-mobile.png'
    },
    {
        'title': 'An 8-hour drive for braids: Why Black haircare is hard in small-town Canada',
        'source': 'Global News',
        'url': 'https://globalnews.ca/news/6578482/black-hair-products-cost/',
        'image': '/public/news_clips/global1.png',
        'image-mobile': '/public/news_clips/global-mobile.png'
    },
    {
        'title': `There's a lack of Black hair services in Nova Scotia. This woman wants to change that`,
        'source': 'CBC News',
        'url': 'https://www.cbc.ca/news/canada/nova-scotia/black-textured-hair-school-cosmetology-association-1.5668785',
        'image': '/public/news_clips/cbc1.png',
        'image-mobile': '/public/news_clips/cbc1-mobile.png'
    },
    {
        'title': 'Black hair services a struggle to access in Atlantic Canada but play important role in cultural tradition',
        'source': 'Saltwire',
        'url': 'https://www.saltwire.com/atlantic-canada/lifestyles/black-hair-services-a-struggle-to-access-in-atlantic-canada-but-play-important-role-in-cultural-tradition-100589578/',
        'image': '/public/news_clips/saltwire.png',
        'image-mobile': '/public/news_clips/saltwite-mobile.png'
    }
]